import React, {useEffect, useRef} from "react"
import Layout from "../../components/interior/layout-int"
import SEO from "../../components/seo"

const NosotrosPage = (props) => {
const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            if (typeof window !== "undefined") {
                window.funciones()
            }
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="Politicas de privacidad"/>
            <>
                <section className="parallax bg-extra-dark-gray header-int" data-parallax-background-ratio="0.5"
                         style={{backgroundImage: 'url("/images/lca/header1.jpg")'}}>
                    <div className="opacity-extra-medium bg-extra-dark-gray"/>
                    <div className="container">
                        <div className="header row align-items-stretch justify-content-center small-screen">
                            <div
                                className="col-12  page-title-extra-small text-center d-flex justify-content-center flex-column">
                                <h2 className="text-white alt-font font-weight-500 letter-spacing-minus-1px line-height-50 sm-line-height-45 xs-line-height-30 no-margin-bottom">
                                    Términos y condiciones</h2>
                            </div>
                        </div>
                    </div>
                </section>
                {/* end page title */}
                {/* start section */}
                <section id="down-section">
                    <div className="container">
                        <div className="row align-items-center">

                            <div className="col-12 ">
                                <p>GENERALES. Latam Capital Asesoria e Infraestructura, S.C.  (“LCA” o “Nosotros”) opera este sitio de Internet
                                    (el “Sitio”) y proporciona servicios que incluyen, entre otros, servicios brindados
                                    a nombre propio y en nombre de sus afiliadas. A usted, como usuario del Sitio, se le
                                    denomina en el presente documento como “usted” o “usuario”. </p>

                                <p>A continuación se presentan los Términos y Condiciones de Uso y la Política de Uso
                                    Aceptable, que explican la manera mediante la cual usted puede utilizar el Sitio.
                                    Nuestra Política de Privacidad explica la manera mediante la cual recopilamos
                                    información relacionada a usted en el Sitio, el uso de la misma y la manera mediante
                                    la cual puede comunicarse con Nosotros en relación a dicha información. Por favor
                                    <a href="https://latamcapitaladvisors.com/spanish/privacyPolicy.html"
                                       target="_blank" rel="noreferrer" >haga clic aquí para ver</a> a política de privacidad. Los
                                    Términos y Condiciones de Uso,
                                    la Política de Uso Aceptable y la Política de Privacidad se denominan conjuntamente
                                    en este documento como los “Términos en Línea”.</p>

                                <p>Los Términos en Línea tienen vigencia a partir de enero de 2010 y modificado el 31 de
                                    julio de 2011. Favor de leer con detenimiento la totalidad de los Términos en Línea
                                    antes de utilizar el Sitio, ya que dichos Términos pudieran haber sido modificados
                                    desde la última vez que utilizó el Sitio.
                                    Al utilizar el Sitio, entre otras cosas, mediante la descarga de materiales del
                                    Sitio, usted consiente su aceptación de los Términos en Línea. Si usted no acepta
                                    los Términos en Línea, no descargue materiales del Sitio ni utilice el Sitio de
                                    ninguna manera.</p>

                                <p>LCA se reserva el derecho, bajo su discreción, de cambiar, modificar, agregar o
                                    eliminar cualquier parte de los Términos en Línea, ya sea en su totalidad o en
                                    parte, en cualquier momento. Usted deberá examinar los Términos en Línea en forma
                                    regular, ya que las modificaciones entrarán en vigencia en el momento en que las
                                    mismas se realicen. Por este medio usted ratifica y acepta que si continúa
                                    utilizando el Sitio luego de haberse realizado una modificación en los Términos en
                                    Línea, dicha utilización constituirá su aceptación de dichas modificaciones. Le
                                    enviaremos una notificación, ya sea mediante correo electrónico o mediante la
                                    publicación de una notificación en la página de inicio del Sitio, sobre cualquier
                                    modificación sustancial en los Términos en Línea.</p>

                                <p>Ninguno de los contenidos de este Sitio constituye asesoramiento impositivo, legal,
                                    de seguros ni de inversión. Ni la información ni ninguna de las opiniones contenidas
                                    en este Sitio constituye una búsqueda u oferta por parte de LCA para la compra o la
                                    venta de valores, futuros, opciones u otros instrumentos financieros. Las decisiones
                                    tomadas sobre la base de información contenida en este Sitio son responsabilidad
                                    exclusiva del visitante.</p>


                                <h6 className="alt-font font-weight-500 text-extra-dark-gray w-90">
                                    TÉRMINOS Y CONDICIONES DE USO
                                </h6>
                                <p>LICENCIA LIMITADA. Sujeto a que usted cumpla con los Términos y Condiciones de Uso y
                                    la Política de Uso Aceptable, LCA le otorga un derecho limitado no exclusivo e
                                    intransferible para acceder y utilizar el Sitio y la información y materiales
                                    contenidos en el mismo (en forma conjunta, los “Materiales”). LCA le autoriza
                                    visualizar y descargar los Materiales exclusivamente para uso propio, salvo en los
                                    casos en que este documento establezca expresamente lo contrario. La autorización
                                    otorgada en la oración anterior (a) no constituye una transferencia de título sobre
                                    los Materiales, y (b) se encuentra sujeta a las siguientes restricciones: (1) usted
                                    no podrá eliminar ninguna notificación de ni ningún tipo de notificación de
                                    exclusividad contenida en los Materiales, y (2) usted no podrá modificar los
                                    Materiales de ninguna manera ni reproducir o públicamente exhibir, presentar o
                                    distribuir los Materiales para ningún propósito público o comercial. LCA podrá
                                    rescindir la autorización, los derechos y la licencia que se otorgan anteriormente,
                                    bajo su discreción y por cualquier o ningún motivo. Una vez notificada dicha
                                    rescisión a través de métodos que incluyen entre otros la publicación de dichas
                                    modificaciones en el Sitio, usted acepta destruir inmediatamente las copias de todos
                                    los Materiales que se encuentren en ese momento en su poder, ya sea en forma
                                    electrónica o en cualquier otra forma.</p>

                                <p>DERECHOS DE PROPIEDAD Y MARCAS COMERCIALES. Usted reconoce y acepta que el Sitio y
                                    todos los contenidos incluidos en el mismo (lo cual incluye, entre otras cosas,
                                    todos los textos, imágenes, fotografías, ilustraciones, multimedios [por ejemplo,
                                    combinaciones de gráficos, audio y/o video], tablas, índices, informes, datos,
                                    software, código HTML y pantallas) son propiedad de, otorgados bajo licencia a, o de
                                    otro modo son utilizados bajo autorización por LCA y sus socios comerciales y
                                    proveedores de información, y los mismos se encuentran sujetos a las leyes de
                                    propiedad intelectual de los Estados Unidos y otros países, incluidas las leyes que
                                    rigen derechos de patentes, derechos de copyright, secretos comerciales, nombres
                                    comerciales, marcas comerciales, marcas de servicio, derechos morales, conocimientos
                                    técnicos y cualquier otro derecho o activo intangible similar. Cualquier información
                                    recopilada, exhibida, almacenada y archivada en el Sitio y en Nuestros sistemas es
                                    propiedad de LCA, de nuestras afiliadas o de nuestros socios comerciales. El uso no
                                    autorizado de cualquier Marca Comercial, lo cual incluye la reproducción, imitación,
                                    debilitamiento o usos confusos o engañosos, esta prohibido de conformidad con las
                                    leyes de marcas comerciales de los Estados Unidos. Las Marcas Comerciales no podrán
                                    utilizarse en relación con ningún producto o servicio que no se origine en LCA, de
                                    ninguna forma que tenga probabilidades de ocasionar confusión entre los usuarios, ni
                                    de ninguna forma que menosprecie o desacredite a LCA. Todas las demás marcas
                                    comerciales que aparezcan en el Sitio son propiedad de sus respectivos
                                    propietarios.</p>

                                <p>INFORMACIÓN PROPORCIONADA POR USTED. A excepción de cierta información de
                                    identificación personal (según se describe en nuestra Política de Privacidad), LCA
                                    no desea que usted le proporcione, y usted no deberá proporcionarle , a través del
                                    Sitio ningún tipo de información que usted considere como confidencial o exclusiva
                                    de usted o de cualquier otra persona. Si usted nos envía cualquier tipo de
                                    información con dichas características, perderá todos los derechos sobre dicha
                                    información, y usted acepta que cualquier tipo de información de dichas
                                    características que usted o un individuo que actúe en nombre de usted le suministre
                                    a LCA a través del Sitio no se considerará como confidencial ni exclusiva.</p>

                                <p>INFORMACIÓN ÚNICAMENTE CON FINES INFORMATIVOS. Los Materiales se suministran
                                    únicamente con fines informativos y no constituyen una oferta de venta ni la
                                    búsqueda de una oferta de compra de ningún tipo de valores ni de ningún tipo de
                                    producto de seguros. Los productos y servicios de LCA se encuentran sujetos
                                    únicamente a los términos de la documentación que acompañe la prestación de
                                    cualquiera de dichos productos y servicios y usted deberá consultar dicha
                                    documentación para obtener información completa al respecto. No todos los productos
                                    o servicios que se describen en este Sitio se encuentran disponibles en todas las
                                    jurisdicciones o a todos los posibles clientes o inversionistas. Ninguno de los
                                    contenidos de este Sitio tiene por objeto constituir, ni deberá ser considerado
                                    como, asesoramiento en inversiones ni una oferta o búsqueda de ningún tipo, lo cual
                                    incluye entre otras cosas la información proporcionada en este Sitio en cualquier
                                    jurisdicción o a cualquier posible cliente o inversionista donde cualquier
                                    información relacionada a productos y servicios pueda ser interpretada como una
                                    oferta o búsqueda que de otro modo no califique o quede exenta de regulación.</p>

                                <p>CONTENIDO Y OPERACIÓN DEL SITIO. La información contenida en el Sitio se encuentra
                                    sujeta a modificación sin previo aviso a usted ni a ninguna otra persona. LCA no
                                    asume ninguna obligación de mantener la información en el Sitio ni garantizar que
                                    dicha información sea la información más actualizada disponible. LCA podrá
                                    finalizar, modificar, suspender o discontinuar cualquier aspecto del Sitio, lo cual
                                    incluye la disponibilidad de cualquier característica del Sitio, en cualquier
                                    momento. LCA también podrá imponer límites, por cualquier o ningún motivo, sobre
                                    ciertas características y servicios, o restringir su acceso a la totalidad o a
                                    partes del Sitio sin proporcionar una notificación previa ni asumir
                                    responsabilidad.</p>

                                <p>SOLICITUDES DE INFORMACIÓN. Salvo en relación con la respuesta a notificaciones de
                                    retiro o la respuesta a preguntas relacionadas a la información de identificación
                                    personal (según se describe en nuestra Política de Privacidad), LCA no tiene
                                    obligación alguna de responder a ninguna solicitud de información ni a ninguna otra
                                    solicitud que usted envíe a LCA, y LCA se exime expresamente de toda responsabilidad
                                    de responder o no responder a dichas solicitudes. LCA también se exime expresamente
                                    de cualquier tipo de responsabilidad de enviarle alertas o información para
                                    inversionistas a solicitud de usted y se exime de todo tipo de responsabilidad por
                                    el contenido de dichas alertas e información para inversionistas.</p>

                                <p>LIBERACIÓN DE RESPONSABILIDAD DE GARANTÍAS. ESTE SITIO SE LE PROPORCIONA “TAL Y COMO
                                    ESTÁ” SIN GARANTÍAS DE NINGÚN TIPO, YA SEAN EXPRESAS O IMPLÍCITAS. USTED
                                    EXPRESAMENTE COMPRENDE Y ACEPTA QUE LCA NO EFECTÚA NINGÚN TIPO DE MANIFESTACIÓN NI
                                    GARANTÍA EN RELACIÓN A LA CONFIABILIDAD, PUNTUALIDAD, CALIDAD, IDONEIDAD,
                                    DISPONIBILIDAD, EXACTITUD O INTEGRIDAD DEL SITIO O DE CUALQUIER CONTENIDO INCLUIDO
                                    EN EL MISMO. LCA NO MANIFIESTA NI GARANTIZA, Y POR ESTE MEDIO SE EXIME DE, CUALQUIER
                                    MANIFESTACIÓN O GARANTÍA QUE INDIQUE QUE: (A) EL USO DEL SITIO SERÁ OPORTUNO,
                                    ININTERRUMPIDO O LIBRE DE ERRORES O QUE FUNCIONARÁ EN COMBINACIÓN CON CUALQUIER OTRO
                                    HARDWARE, SOFTWARE, SISTEMA O DATOS, (B) EL SITIO CUMPLIRÁ CON SUS REQUISITOS O
                                    EXPECTATIVAS, (C) LA CALIDAD DE CUALQUIER PRODUCTO, SERVICIO, INFORMACIÓN U OTRO
                                    MATERIAL OBTENIDO POR USTED A TRAVÉS DEL SITIO CUMPLIRÁ CON SUS REQUISITOS O
                                    EXPECTATIVAS, (D) LOS ERRORES O DEFECTOS SERÁN CORREGIDOS, Y (E) SU UTILIZACIÓN DEL
                                    SITIO, O EL/LOS SERVIDOR(ES) QUE GARANTIZA(N) LA DISPONIBILIDAD DEL SITIO, SE
                                    ENCUENTRAN LIBRES DE “GUSANOS” (WORMS), “TROYANOS” (TROJAN HORSES), VIRUS U OTROS
                                    COMPONENTES PERJUDICIALES. HASTA EL GRADO MÁXIMO PERMITIDO POR LAS LEYES APLICABLES,
                                    NOS EXIMIMOS DE TODAS LAS CONDICIONES, MANIFESTACIONES Y GARANTÍAS DE CUALQUIER
                                    TIPO, EN FORMA DIRECTA O INDIRECTA, YA SEAN EXPRESAS O IMPLÍCITAS, ESTATUTARIAS O DE
                                    OTRO TIPO QUE INCLUYEN, ENTRE OTRAS, GARANTÍAS DE TÍTULO, GARANTÍAS IMPLÍCITAS DE
                                    COMERCIABILIDAD O APTITUD PARA UN FIN ESPECÍFICO, O LA NO VIOLACIÓN DE DERECHOS DE
                                    TERCEROS, EN RELACIÓN A CUALQUIER PRODUCTO O SERVICIO QUE SE SUMINISTRE EN VIRTUD
                                    DEL PRESENTE DOCUMENTO. NINGUNA INFORMACIÓN O ASESORAMIENTO, YA SEA EN FORMA ORAL O
                                    ESCRITA, QUE NOSOTROS O CUALQUIERA DE NUESTROS REPRESENTANTES PROPORCIONEMOS
                                    GENERARÁ NINGÚN TIPO DE GARANTÍA CON RESPECTO AL SITIO.</p>

                                <p>LIMITACIÓN DE RESPONSABILIDAD. EN NINGÚN CASO SE NOS RESPONSABILIZARÁ A NOSOTROS NI A
                                    NINGUNA DE NUESTRAS AFILIADAS, NI A NINGUNO DE LOS FUNCIONARIOS, DIRECTORES,
                                    ACCIONISTAS, EMPLEADOS, AGENTES, REPRESENTANTES, SOCIOS O LICENCIANTES
                                    CORRESPONDIENTES A NOSOTROS NI A NUESTRAS AFILIADAS (LAS “PARTES DE LCA”) (1) POR
                                    NINGUNA PÉRDIDA O DAÑO QUE SURJA DEL HECHO DE QUE USTED NO CUMPLA CON LOS TÉRMINOS Y
                                    CONDICIONES DE USO O LA POLÍTICA DE USO ACEPTABLE, (2) EN VIRTUD DE NINGUNA TEORÍA
                                    DE RESPONSABILIDAD (LO CUAL INCLUYE, ENTRE OTRAS, NEGLIGENCIA Y RESPONSABILIDAD
                                    ESTRICTA) POR NINGÚN DAÑO DIRECTO, INDIRECTO, IMPREVISTO, ESPECIAL, PUNITIVO O
                                    RESULTANTE O POR PÉRDIDA DE GANANCIAS O INGRESOS (YA SEA EN UNA ACCIÓN SOBRE LA BASE
                                    DE CONTRATO, CULPA EXTRA CONTRACTUAL, RESPONSABILIDAD DEL PRODUCTO, ESTATUTO O DE
                                    ALGÚN OTRO MODO, AUNQUE SE NOS HAYA INFORMADO DE LA POSIBILIDAD DE DICHOS DAÑOS) QUE
                                    SURJAN DE (A) EL USO QUE USTED HAGA DEL SITIO, LO CUAL INCLUYE ENTRE OTRAS COSAS
                                    CUALQUIER PÉRDIDA O DAÑO OCASIONADOS POR CUALQUIER CONFIANZA DEPOSITADA EN CUALQUIER
                                    TIPO DE INFORMACIÓN A LA CUAL SE ACCEDA A TRAVÉS DEL SITIO O INCLUIDA EN EL MISMO, O
                                    CUALQUIER DEMORA, INEXACTITUD, ERROR U OMISIÓN ENCONTRADOS EN DICHA INFORMACIÓN; (B)
                                    CUALQUIER OPERACIÓN CELEBRADA A TRAVÉS DEL SITIO O DESDE EL MISMO; (C) EL HECHO DE
                                    QUE USTED NO PUEDA UTILIZAR EL SITIO O LA INFORMACIÓN CONTENIDA EN ÉL POR CUALQUIER
                                    MOTIVO, LO CUAL INCLUYE ENTRE OTRAS COSAS FALLAS DE COMUNICACIONES O CUALQUIER OTRA
                                    FALLA RELACIONADA CON LA TRANSMISIÓN O EL ENVÍO DE CUALQUIER INFORMACIÓN A LA CUAL
                                    SE ACCEDA EN EL SITIO O A TRAVÉS DEL MISMO; (D) LA ELIMINACIÓN, CORRECCIÓN,
                                    DESTRUCCIÓN, DAÑO, PÉRDIDA U OMISIÓN DE ALMACENAMIENTO DE CUALQUIER TIPO DE
                                    INFORMACIÓN; (E) EL USO DE CUALQUIER PRODUCTO O SERVICIO OBTENIDO EN EL SITIO O A
                                    TRAVÉS DEL MISMO; (F) EL ACCESO NO AUTORIZADO AL SITIO Y LA MODIFICACIÓN NO
                                    AUTORIZADA DE SUS TRANSMISIONES O DATOS; (G) LAS DECLARACIONES O LAS CONDUCTAS DE
                                    CUALQUIER TERCERO EN EL SITIO; O (H) CUALQUIER OTRO ASUNTO RELACIONADO CON EL SITIO
                                    O CON EL USO QUE USTED HAGA DEL MISMO.</p>

                                <p>DEBIDO A QUE ALGUNAS JURISDICCIONES NO PERMITEN LA EXCLUSIÓN DE CIERTAS GARANTÍAS O
                                    LA LIMITACIÓN O EXCLUSIÓN DE RESPONSABILIDAD POR DAÑOS IMPREVISTOS O RESULTANTES, EN
                                    DICHAS JURISDICCIONES NUESTRA RESPONSABILIDAD TOTAL QUEDARÁ LIMITADA AL GRADO
                                    PERMITIDO POR LAS LEYES DE DICHA JURISDICCIÓN. EN NINGÚN CASO NUESTRA
                                    RESPONSABILIDAD TOTAL ANTE USTED POR TODAS LAS PÉRDIDAS O DAÑOS CON RESPECTO AL
                                    SITIO PODRÁ SUPERAR LA SUMA DE $50.00.
                                    INDEMNIZACIÓN. Usted acepta indemnizar, eximir y, a opción nuestra, defender a
                                    cualquiera de las Partes de LCA contra todos los daños, costos, responsabilidades,
                                    acciones judiciales, fallos, penalizaciones, gastos, obligaciones, pérdidas,
                                    reclamaciones, acciones, costos y gastos (lo cual incluye entre otras cosas los
                                    honorarios y gastos de abogados) que se relacionen con o surjan de (a) el
                                    incumplimiento por parte de usted de estos Términos y Condiciones de Uso y/o de la
                                    Política de Uso Aceptable, y/o (b) cualquier uso del Sitio por parte de usted, por
                                    parte de cualquier persona autorizada por usted o por parte de una persona que
                                    utilice el Sitio con su contraseña, ya sea que dicho uso haya sido o no autorizado
                                    por usted.</p>

                                <p>SITIOS DE TERCEROS. Este Sitio incluye contenido proporcionado por terceros y puede
                                    incluir enlaces a sitios de Internet operados por terceros que no son LCA, lo cual
                                    incluye a nuestros socios comerciales, proveedores de información, anunciantes y
                                    otros terceros. Dicho contenido y dichos enlaces se suministran exclusivamente para
                                    comodidad de usted. LCA no avala ni asume responsabilidad por ningún tipo de
                                    información, publicidad, servicio o producto puestos a disposición por parte de
                                    terceros, en sus sitios de Internet o a través de éstos, ni por ninguna de las
                                    prácticas de privacidad o términos de uso de dichos terceros. Si usted utiliza dicho
                                    contenido o dichos sitios, usted abandonará este Sitio. Si usted decide examinar
                                    contenido en un sitio de terceros, lo hace bajo su propio riesgo y será
                                    responsabilidad única y exclusivamente de usted tomar todas las medidas de
                                    protección necesarias para protegerse contra virus u otras propiedades perjudiciales
                                    o destructivas, lo cual incluye entre otras cosas aquellas que pudieran ocasionar
                                    fallas o desperfectos del sistema, una vez realizado el traslado a otros sitios. Nos
                                    reservamos el derecho de quitar contenidos proporcionados por terceros y eliminar
                                    cualquier enlace a un sitio de terceros en cualquier momento. Los contenidos
                                    proporcionados por un tercero, o los enlaces a un sitio de terceros, no implican que
                                    LCA patrocine, avale, se encuentre afiliada o asociada con, o esté legalmente
                                    autorizada a utilizar ninguna marca comercial, nombre comercial, logotipo o símbolo
                                    de copyright exhibido en dichos contenidos o accesible a través de dichos enlaces,
                                    ni que ningún tercero que proporcione contenidos o que opere un sitio vinculado esté
                                    autorizado a utilizar ninguna marca comercial, nombre comercial, logotipo o símbolo
                                    de copyright de LCA ni de ninguna de sus afiliadas o subsidiarias.</p>

                                <p>SIN LIMITAR LO PREVIAMENTE MENCIONADO, USTED ACEPTA QUE UTILIZA INFORMACIÓN,
                                    SERVICIOS O PRODUCTOS PROVENIENTES DE UN TERCERO OFRECIDOS EN UN SITIO DE INTERNET
                                    VINCULADO A O QUE DE OTRO MODO SE MENCIONE EN EL SITIO O A TRAVÉS DEL MISMO, DICHO
                                    TERCERO SERÁ EL ÚNICO RESPONSABLE DE DICHA INFORMACIÓN, SERVICIOS O PRODUCTOS, Y POR
                                    ESTE MEDIO USTED EXIME A TODAS LAS PARTES DE LCA DE CUALQUIER RECLAMACIÓN, DEMANDA Y
                                    DAÑO (LO CUAL INCLUYE, ENTRE OTROS, DAÑOS DIRECTOS, INDIRECTOS, IMPREVISTOS,
                                    ESPECIALES, PUNITIVOS O RESULTANTES) DE CUALQUIER TIPO Y NATURALEZA, CONOCIDO Y
                                    DESCONOCIDO, SOSPECHADO Y NO SOSPECHADO, DIVULGADO Y NO DIVULGADO, QUE SURJA DE O
                                    QUE SE RELACIONE DE CUALQUIER MANERA CON DICHA INFORMACIÓN, SERVICIO O PRODUCTO,
                                    CUALQUIER OPERACIÓN ENTRE USTED Y DICHO TERCERO Y CUALQUIER DISPUTA ENTRE USTED Y
                                    DICHO TERCERO.</p>

                                <p>RECLAMACIONES POR COPYRIGHT Y AGENTE DESIGNADO. Respetamos los derechos de propiedad
                                    intelectual de los demás y exigimos que las personas y entidades que utilicen el
                                    Sitio hagan lo mismo. Bajo nuestra discreción, nos reservamos el derecho de dar por
                                    terminado el acceso al Sitio de cualquier persona o entidad que según nuestra
                                    opinión sea o pueda ser responsable de una violación de copyright.
                                    La Ley de Derechos de Autor del Milenio Digital (Digital Millennium Copyright Act,
                                    DMCA) proporciona un mecanismo para notificar a los proveedores de servicio con
                                    respecto a reclamaciones por uso no autorizado de materiales sujetos a derecho de
                                    autor. De conformidad con la DMCA, debe enviarse una reclamación al Agente Designado
                                    del proveedor de servicio. Si usted considera de buena fe que LCA debería recibir la
                                    notificación de una posible violación de derechos de autor en línea que involucre al
                                    Sitio, favor de notificar al Agente Designado de LCA utilizando la siguiente
                                    información de contacto:</p>

                                <p>Torre Optima 1 <br/>
                                    Av. Paseo de las Palmas No. 405-404 <br/>
                                    Lomas de Chapultepec <br/>
                                    C.P. 11000, México, D.F., México <br/>
                                    52-55-5202-6080 <br/>
                                    o a: <a href="mailto:info@latamcapitaladvisors.com"
                                            target="_blank">Info@Latamcapitaladvisors.com</a></p>

                                <p>Tenga en cuenta que, para que se haga efectiva, su notificación de reclamación deberá
                                    cumplir con los requisitos detallados que se establecen en la DMCA. Se le recomienda
                                    examinar dichos requisitos (consulte el Título 17 del Código de los Estados Unidos,
                                    Sección 512 (3)) antes de enviar su reclamación.
                                    Favor de no enviar ninguna otra notificación o comunicación al Agente Designado, que
                                    ha sido nombrado exclusivamente con el propósito de recibir notificaciones de
                                    reclamaciones de derechos de autor de conformidad con la DMCA.</p>

                                <p>USUARIOS FUERA DE LOS ESTADOS UNIDOS Y ELECCIÓN DEL DERECHO APLICABLE. Este Sitio es
                                    controlado, operado y administrado por Nosotros desde nuestras oficinas dentro de
                                    los Estados Unidos y toda la información que usted nos envíe será almacenada en
                                    servidores ubicados en los Estados Unidos. LCA no efectúa ninguna manifestación que
                                    indique que los Materiales resulten apropiados o se encuentren disponibles para su
                                    uso en otras ubicaciones fuera de los Estados Unidos, y se prohíbe el acceso a los
                                    Materiales en territorios en los que sus contenidos sean ilegales. Si usted accede a
                                    este Sitio desde una ubicación fuera de los Estados Unidos, usted será responsable
                                    de cumplir con todas las leyes locales. Estos Términos y Condiciones de Uso y la
                                    Política de Uso Aceptable estarán regidos por las leyes del Estado de New York,
                                    independientemente de sus principios o normas de conflicto de leyes en la medida en
                                    que dichos principios o normas no sean obligatoriamente aplicables por estatuto y
                                    requieran o permitan la aplicación de las leyes de otra jurisdicción. Sin limitar el
                                    derecho de LCA a iniciar acciones contra usted en cualquier jurisdicción, usted
                                    acepta además someterse a la jurisdicción no exclusiva de, y ratifica y acepta que
                                    la jurisdicción adecuada recaerá en los tribunales federales y estatales ubicados en
                                    el Condado de New York, New York o en el Condado de Westchester, White Plains, New
                                    York, con respecto a cualquier reclamación, acción o procedimiento que surja de este
                                    Acuerdo o se relacione con el mismo.
                                    SEGURIDAD POR CONTRASEÑA Y NOTIFICACIÓN. Ciertas partes del Sitio están protegidas
                                    por contraseñas o requieren un inicio de sesión y se encuentran restringidas
                                    únicamente a usuarios autorizados. Usted no podrá obtener acceso no autorizado a
                                    dichas partes del Sitio, ni a ningún otro material o información protegidos, a
                                    través de ningún método que no sea puesto a disposición por parte de LCA en forma
                                    intencional para uso específico por parte de usted. Si usted tiene un Número de
                                    Identificación Personal (PIN, por sus siglas en inglés) para el acceso a las áreas
                                    no públicas del Sitio, usted será el único responsable de todas las actividades que
                                    se lleven a cabo en relación con su PIN. En consecuencia, usted deberá tomar todas
                                    las medidas necesarias razonables para proteger la confidencialidad de su PIN.
                                    Notifique de inmediato a LCA en caso de que tenga conocimiento de cualquier
                                    divulgación, pérdida, robo o uso no autorizado de su PIN.</p>

                                <p>PARTES DEL SITIO PROTEGIDAS POR CONTRASEÑA: Respectivo a las zonas del Sitio
                                    protegidas por contraseña, estos Términos serán sustituidos por cualquier ley o
                                    norma regulatoria o cualquier contrato o acuerdo entre LCA y la contraparte que
                                    accede al Sitio. LCA no acepta responsabilidad, y no asumirá obligación alguna, por
                                    cualquier Material colocado en las zonas del Sitio protegidas por contraseña. </p>

                                <p>TÉRMINOS ADICIONALES. Estos Términos en Línea constituyen la totalidad del acuerdo
                                    entre LCA y usted con respecto a la utilización del Sitio. Cualquier causa de acción
                                    que usted tenga con respecto a la utilización del Sitio deberá iniciarse en el plazo
                                    de un (1) año a partir del momento en que surja la causa de acción, y por este medio
                                    usted renuncia a cualquier derecho, ya sea en forma estatutaria o de otro modo, a
                                    cualquier extensión adicional de tiempo más allá de dicho período de un (1) año. Si
                                    por cualquier motivo un tribunal de jurisdicción competente determina que alguna
                                    disposición de estos Términos en Línea no puede hacerse cumplir, el resto de las
                                    disposiciones que se establecen en este documento continuará en pleno vigor.</p>

                                <p>Torre Optima 1 <br/>
                                    Av. Paseo de las Palmas No. 405-404 <br/>
                                    Lomas de Chapultepec <br/>
                                    C.P. 11000, México, D.F., México <br/>
                                    52-55-5202-6080 <br/>
                                    o a: <a href="mailto:info@latamcapitaladvisors.com"
                                            target="_blank">Info@Latamcapitaladvisors.com</a></p>


                                <p>En los casos en que sea posible, todas las reclamaciones deberán incluir detalles que
                                    pudieran ayudar a LCA en la investigación y resolución de la reclamación (por
                                    ejemplo, una copia del material ofensivo).</p>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        </Layout>
    )
}

export default NosotrosPage
